import Layout from "../../layouts"
import React, {useState, useContext, useEffect} from "react"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {Box, Card} from "@mui/material"

import Hider from "../../components/utils/Hider"
import CfpModify from "../../components/cfp/cfpmodify"
import {CfpBackTitle} from "../../components/cfp/cfpdetailstop"
import Cfptb from "../../components/cfp/cfptb"
import {cfpColumns} from "../../components/utils/cfpcolumns"
import {navigate} from "gatsby"
import CheckMemberTable from "../../components/attendance/checkMemberTable"
import {gUtils} from "../../components/utils/gutils";


export default function MemberGroupDetails({location = {}}) {
    const {state = {}} = location
    const [edit, setEdit] = useState(false)
    const [data, setData] = useState([])
    const [title, setTitle] = useState("")
    const [items, setItems] = useState([])
    const [listMember, setListMember] = useState([])
    const [members,setMembers]=useState([])
    const {setCustomModalOpen, gCfp, setModalContent, gMemberDevice,gStripe,gGroupDevice} =
        useContext(GlobalStateContext)


    useEffect(() => {
        console.log("state.data",state)

        if (state){
            setData(state.data)
            setTitle(state.data.name)


            setMembers( state.data.members.map(item=>{
                let  data='常時利用'
                if (item.startTime && item.endTime) {
                    // Convert UNIX timestamps from seconds to milliseconds by multiplying by 1000
                    data=gUtils.getStartTimeEndTime(item)
                }



                return {...item,msgdata:data}

            }))
            setItems(gCfp.uuidByMm(state.data.uuids))
        }else {
            navigate('/')
        }

    }, [state])
    const handleSureClick = (v) => {
        setEdit(false)
        gMemberDevice.updateMemberDevice(
            {
                cid: data.cid,
                gid: data.gid,
                name: v,
            },
            () => {
                setData((item) => ({...item, name: v}))
                setTitle(v)
            }
        )
    }


    useEffect(() => {

        console.log("当前", gCfp.listMm, items)
        setListMember(gCfp.listMm.filter(item => !items.some(im => im.subUUID === item.subUUID)))

    }, [gCfp.listMm, items])

    const handleEditClick = () => {
        setEdit(true)
    }

    const btnAddDevice = () => {
        setCustomModalOpen(true)
        setModalContent(
            <CheckMemberTable
                title={"ユーザーを選択"}
                data={listMember} //資料
                setOpenModal={setCustomModalOpen} //開關Modal的屬性
                handleCheck={(value) => {
                    setCustomModalOpen(false)
                    // setItems(prevState => [...prevState,...value])
                    apiAddDevice(value)
                }} //勾選項目後要做的處理function
                location={location}
                isWifi={false}
            />
        )
    }
    const apiAddDevice = async (values) => {
        let devices = values.map((item) => item.subUUID)
        let devices2 = items.map((item) => item.subUUID)


        let ndata = [...devices2, ...devices]

        console.log("添加成员", devices, items, ndata, values)
        for (let i = 0; i < values.length; i++) {

            await gMemberDevice.addShareDevices({...values[i], cid: data.cid, mid: data.gid, op: 'addd'})

        }


        gMemberDevice.updateMemberDevice(
            {
                cid: data.cid,
                gid: data.gid,
                uuids: ndata,
            },
            () => {

                setItems(gCfp.uuidByMm(ndata))
            }
        )
    }
    const apiDelDevice = async (values) => {

        for (let i = 0; i < values.length; i++) {

            await gMemberDevice.addShareDevices({...values[i], cid: data.cid, mid: data.gid, op: 'deld'})

        }
        let ndevices = items.filter(item => !values.some(ite => ite.subUUID === item.subUUID)).map(data => data.subUUID)

        console.log("删除成员", items, values, ndevices)

        gMemberDevice.updateMemberDevice(
            {
                cid: data.cid,
                gid: data.gid,
                uuids: ndevices,
            },
            () => {
                setItems(gCfp.uuidByMm(ndevices))
            }
        )
    }
    return (
        <Layout location={location}>
            <Card>
                <Hider show={edit}>
                    <CfpModify
                        type="n"
                        state={data}
                        leftClick={() => setEdit(false)}
                        sureClick={handleSureClick}
                    />
                    <Box>
                        <CfpBackTitle title={title} clickEdit={handleEditClick}/>
                    </Box>
                </Hider>
            </Card>
            <Cfptb
                callAdd={btnAddDevice}
                isAdd={true}
                data={items}
                isBind={false}
                isBack={false}
                text={`ユーザー(${items.length})`}
                columns={cfpColumns.groupMember}
                callDelData={apiDelDevice}
            />
            <Cfptb

                isAdd={false}
                isDel={false}
                isCsv={false}
                data={members}
                isBind={false}
                isBack={false}
                adjustToolBar={true}
                text={`グループ鍵`}
                selectableRows={'none'}
                columns={cfpColumns.groupMemberKey({
                    click:(did)=>{
                        let result=members.find(item=>item.did===did)
                        console.log("members",members)
                        if (result){
                            result={...result,cid:gStripe.customer.id,isDevice:false,op:'dels'}
                            console.log("删除设备组")
                            gGroupDevice.delMemberDevice(result,(d)=>{
                                console.log("删除设备组",d)
                                if (d){
                                    setMembers(prevState => prevState.filter(item=>item.did!==did))
                                }
                            })
                        }
                       console.log("dangqiang",result)
                    },
                    members,
                })}



            />
        </Layout>
    )
}
